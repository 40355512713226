import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './layout/Layout';
import { Home } from './views/Home';
import { Login } from './layout/Login';
import { LoginAD } from './layout/LoginAD';
import toastr from 'toastr';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'fontsource-roboto';
import './antd.css';
import 'rodal/lib/rodal.css';
import './App.css';
import '@fortawesome/fontawesome-free'


import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { readCookie, clearCookies } from './utils/cookieHelpers';
import TeamsRouting from './routing/TeamsRouting';
import IndicatorsRouting from './routing/IndicatorsRouting';
import LinkedIndicatorsRouting from "./routing/LinkedIndicatorsRouting";
import TimetablesRouting from './routing/TimetablesRouting';
import AgendasRouting from './routing/AgendasRouting';
import LinkedAgendasRouting from './routing/LinkedAgendasRouting';
import ChatGroupsRouting from './routing/ChatGroupsRouting';
import OutletUsersRouting from './routing/OutletUsersRouting';
import SnippetsRouting from './routing/SnippetsRouting';
import PauseReasonsRouting from './routing/PauseReasonsRouting';
import OpeningHoursRouting from './routing/OpeningHoursRouting';
import MobilitySolutionsRouting from './routing/MobilitySolutionsRouting';
import TagsRouting from './routing/TagsRouting';
import DossierStacksRouting from './routing/DossierStacksRouting';
import WorkPreparationsRouting from './routing/WorkPreparationsRouting';
import BookingItemsRouting from './routing/BookingItemsRouting';
import AbsencesRouting from './routing/AbsencesRouting';
import AbsenceTypesRouting from './routing/AbsenceTypesRouting';
import ChatMessagesRouting from './routing/ChatMessagesRouting';
import ChatMessageTriggersRouting from './routing/ChatMessageTriggersRouting';
import OccupancyRatesRouting from './routing/OccupancyRatesRouting';
import PaymentConnectionsRouting from './routing/PaymentConnectionsRouting';
import BookingDateMarkersRouting from './routing/BookingDateMarkersRouting';
import TaskWorkflowsRouting from './routing/TaskWorkflowsRouting';
import FirstAppointmentDateRouting from './routing/FirstAppointmentDateRouting';
import OutletWaitSlotsRouting from './routing/OutletWaitSlotsRouting';
import DisclaimersRouting from './routing/DisclaimersRouting';
import AssetGroupsRouting from './routing/AssetGroupsRouting';
import AssetClassesRouting from './routing/AssetClassesRouting';

export default function App() {
    var publicKey;
    var authorizationToken;
    var cookieToken = readCookie("token");

    if (cookieToken !== null) {
        publicKey = cookieToken.slice(0, 36);
        authorizationToken = cookieToken.slice(36);

        localStorage.setItem("publicKey", publicKey);
        localStorage.setItem("authorizationToken", authorizationToken);

        clearCookies();
    }
    else {
        publicKey = localStorage.getItem("publicKey");
        authorizationToken = localStorage.getItem("authorizationToken");
    }
    const { instance } = useMsal();

    const [authTokens, setAuthTokens] = useState({ publicKey, authorizationToken });
    const [currentIdentity, setCurrentIdentity] = useState();
    const [resources, setResources] = useState([]);
    const [fetchingResourcesInProgress, setFetchingResourcesInProgress] = useState(false);
    const isAdAuthenticated = useIsAuthenticated();

    const fetchCurrentIdentity = () => {
        if (authTokens.publicKey == null || authTokens.authorizationToken == null) return;

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'PublicKey': authTokens.publicKey, 'Authorization-token': authTokens.authorizationToken }
        };

        fetch("/api/mobopro/v1/currentidentity", requestOptions)
            .then(response => {
                response.json().then(data => {
                    if (!response.ok) {
                        for (var i = 0; i < data.errors.length; i++) {
                            toastr.error(data.errors[i]);
                        }
                        logout();
                        document.location.href = "/";
                    }
                    else {
                        setCurrentIdentity(data)
                        initialize(data.culture);
                    }
                })
            })
    }

    useEffect(() => {
        fetchCurrentIdentity();
    }, [authTokens.publicKey, authTokens.authorizationToken])


    const login = (publicKey, authorizationToken) => {
        localStorage.setItem("publicKey", publicKey);
        localStorage.setItem("authorizationToken", authorizationToken);

        setAuthTokens({ publicKey, authorizationToken });
    }

    const logout = () => {
        localStorage.removeItem("publicKey");
        localStorage.removeItem("authorizationToken");

        setCurrentIdentity(null);
        setAuthTokens({ publicKey: null, authorizationToken: null });

        if (isAdAuthenticated) {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    const initialize = (culture) => {
        if (resources.length > 0) return;
        if (fetchingResourcesInProgress) return;

        setFetchingResourcesInProgress(true);

        fetch(`/api/mobopro/v1/initialize?cultureCode=${culture}`,
            {
                headers: new Headers({
                    'Accept': 'application/json',
                    'PublicKey': localStorage.getItem('publicKey'),
                    'Authorization-token': localStorage.getItem('authorizationToken'),
                })
            })
            .then(response => response.json())
            .then((result) => {
                var resources = result.resources;
                setResources(resources);
                setFetchingResourcesInProgress(false);
            });
    }

    if (authTokens.publicKey == null || authTokens.authorizationToken == null) {
        if (!isAdAuthenticated) return (<Login loginCallback={login} />)
        return (<LoginAD loginCallback={login} />)
    }
    if (currentIdentity === null) return (<div className="centered-loader"><CircularProgress /></div>)
    if (resources.length === 0) return (<div className="centered-loader"><CircularProgress /></div>)

    return (
        <Layout currentIdentity={currentIdentity} logoutCallback={logout} resources={resources}>
            <Route exact path='/' render={(props) => (<Home location={props.location} resources={resources} />)} />
            <TeamsRouting currentIdentity={currentIdentity} resources={resources} />
            <IndicatorsRouting currentIdentity={currentIdentity} resources={resources} />
            <LinkedIndicatorsRouting currentIdentity={currentIdentity} resources={resources} />
            <TimetablesRouting currentIdentity={currentIdentity} resources={resources} />
            <AgendasRouting currentIdentity={currentIdentity} resources={resources} />
            <LinkedAgendasRouting currentIdentity={currentIdentity} resources={resources} />
            <ChatGroupsRouting currentIdentity={currentIdentity} resources={resources} />
            <PaymentConnectionsRouting currentIdentity={currentIdentity} resources={resources} />
            <OutletUsersRouting currentIdentity={currentIdentity} resources={resources} />
            <SnippetsRouting currentIdentity={currentIdentity} resources={resources} />
            <PauseReasonsRouting currentIdentity={currentIdentity} resources={resources} />
            <OpeningHoursRouting currentIdentity={currentIdentity} resources={resources} />
            <MobilitySolutionsRouting currentIdentity={currentIdentity} resources={resources} />
            <TagsRouting currentIdentity={currentIdentity} resources={resources} />
            <DossierStacksRouting currentIdentity={currentIdentity} resources={resources} />
            <WorkPreparationsRouting currentIdentity={currentIdentity} resources={resources} />
            <BookingItemsRouting currentIdentity={currentIdentity} resources={resources} />
            <AbsencesRouting currentIdentity={currentIdentity} resources={resources} />
            <AbsenceTypesRouting currentIdentity={currentIdentity} resources={resources} />
            <ChatMessagesRouting currentIdentity={currentIdentity} resources={resources} />
            <ChatMessageTriggersRouting currentIdentity={currentIdentity} resources={resources} />
            <OccupancyRatesRouting currentIdentity={currentIdentity} resources={resources} />
            <BookingDateMarkersRouting currentIdentity={currentIdentity} resources={resources} />
            <TaskWorkflowsRouting currentIdentity={currentIdentity} resources={resources} />
            <FirstAppointmentDateRouting currentIdentity={currentIdentity} resources={resources} />
            <OutletWaitSlotsRouting currentIdentity={currentIdentity} resources={resources} />
            <DisclaimersRouting currentIdentity={currentIdentity} resources={resources} />
            <AssetGroupsRouting currentIdentity={currentIdentity} resources={resources} />
            <AssetClassesRouting currentIdentity={currentIdentity} resources={resources} />
        </Layout>
    );
}
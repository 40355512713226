import { Box, TextField } from "@material-ui/core";
import React from "react";

const NumericFieldMandatoryComponent = ({
  value,
  handleChange,
  labelText,
  id,
  maxLength,
  placeholderText,
}) => {
  return (
    <Box display={"flex"} marginBottom={2} flexDirection={"column"}>
      <TextField
        className="col-12"
        type="numeric"
        id={`${id}`}
        value={value}
        label={labelText}
        variant="outlined"
        name={id}
        onChange={handleChange}
        placeholder={placeholderText}
        inputProps={{ maxLength: maxLength, min: 0 }}
        InputLabelProps={{
          shrink: true,
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            maxWidth: "100%",
          },
        }}
      />
    </Box>
  );
};

export default NumericFieldMandatoryComponent;

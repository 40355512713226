export const CreateAssetGroupUrl = `api/mobopro/v1/assets/groups`;
export const GetAssetGroupsUrl = (onlyactive) => {
  return `api/mobopro/v1/assets/groups?onlyactive=${onlyactive}`;
};

export const DeleteAssetGroupUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};
export const UpdateAssetGroupUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};
export const GetAssetGroupByIdUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};

export const CreateAssetClassUrl = `api/mobopro/v1/assets/classes`;
export const GetAssetClassesUrl = (groupId) => {
  if (groupId) {
    return `api/mobopro/v1/assets/classes?includeall=true&group=${groupId}`;
  }
  return `api/mobopro/v1/assets/classes?includeall=true`;
};

export const DeleteAssetClassUrl = (id) => {
  return `api/mobopro/v1/assets/classes/${id}`;
};
export const UpdateAssetClassUrl = (id) => {
  return `api/mobopro/v1/assets/classes/${id}`;
};
export const GetAssetClassByIdUrl = (id) => {
  return `api/mobopro/v1/assets/classes/${id}`;
};

import { Box, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const AddItemButton = ({ linkPath, title, idName, state }) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      sx={{ mb: "10px" }}
      width={"100%"}
    >
      <Link
        to={{
          pathname: linkPath,
          state: state,
        }}
        style={{ textDecoration: "none" }}
      >
        <Button id={"add-asset-" + idName} variant="contained" color="primary">
          <i className="fas fa-plus mr-2" title={title} />
          {title}
        </Button>
      </Link>
    </Box>
  );
};

export default AddItemButton;

import { Box, TextField, FormHelperText } from "@material-ui/core";
import React from "react";

const TextFieldMandatoryComponent = ({
  value = "",
  handleChange,
  labelText,
  id,
  maxLength,
  helperText,
  placeholderText,
}) => {
  return (
    <Box display={"flex"} marginBottom={2} flexDirection={"column"}>
      <TextField
        className="col-12"
        id={id}
        value={value}
        label={labelText}
        variant="outlined"
        name={id}
        onChange={handleChange}
        multiline
        placeholder={placeholderText}
        inputProps={{
          maxLength: maxLength,
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            maxWidth: "100%",
          },
        }}
      />
      {helperText && (
        <FormHelperText
          style={{ marginLeft: "14px", marginRight: "14px" }}
          error={!value}
        >
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default TextFieldMandatoryComponent;

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Modal,
  Paper,
  Select,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import { DeleteOutline } from "@material-ui/icons";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DisplayMessage } from "../../../../services/helpers";
import { ModalContent } from "../../../../styles/box/styles";
import { CancelButton, OkButton } from "../../../../styles/button/styles";
import { useStyles } from "../../../../styles/styles";
import { LanguagesWithLanguageCode } from "../../../../utils/constants";
import { TableIcons } from "../../../../utils/TableIcons";
import { LocalizationTheme } from "../../../../utils/utils";
import {
  copyDisclaimer,
  deleteDisclaimer,
  getDisclaimers,
} from "../Disclaimers/services/disclaimersApisService";

const DisclaimersTable = (props) => {
  const { resources, accessibleMoboOutlets, outletId, refreshGrid } = props;
  const [selectedOutlet, setSelectedOutlet] = useState(outletId ? outletId : 0);
  const [disclaimers, setDisclaimers] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [copiedDisclaimer, setCopiedDisclaimer] = useState({
    id: 0,
    moboOutlet: "",
    type: "",
    language: "",
  });
  const [copyToOutlet, setCopyToOutlet] = useState(0);
  const [copyToLanguage, setCopyToLanguage] = useState("0");
  const [deleteDisclaimerId, setDeleteDisclaimerId] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const classes = useStyles();

  const tableSettings = {};

  tableSettings.actions = [
    {
      icon: () => <DeleteOutline />,
      tooltip: resources["Delete"],
      onClick: (event, rowData) => handleDelete(rowData.id),
    },

    {
      icon: () => <FileCopyIcon />,
      tooltip: resources["Copy"],
      onClick: (event, rowData) => handleCopy(rowData),
    },
  ];

  tableSettings.columns = [
    {
      title: resources["Outlet"],
      field: "moboOutlet",
      cellStyle: {
        width: "25%",
        minWidth: "150px",
      },
      headerStyle: {
        width: "25%",
        minWidth: "150px",
      },
    },
    {
      title: resources["Type"],
      field: "type",
      cellStyle: {
        width: "55%",
        minWidth: "300px",
      },
      headerStyle: {
        width: "55%",
        minWidth: "300px",
      },
    },
    {
      title: resources["Language"],
      field: "language",
      cellStyle: {
        width: "10%",
        minWidth: "100px",
      },
      headerStyle: {
        width: "10%",
        minWidth: "100px",
      },
    },
  ];

  const handleDelete = (id) => {
    setDeleteDisclaimerId(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDelete = () => {
    setDeleteDisclaimerId(0);
    setDeleteModalOpen(false);
  };

  const handleSubmitDelete = async () => {
    setRequestInProgress(true);

    var result = await deleteDisclaimer(deleteDisclaimerId);

    setRequestInProgress(false);
    handleCloseDelete();

    if (result === true) {
      DisplayMessage(resources["DisclaimerWasDeletedWithSuccess"], "success");
      setRefresh(true);
    }
  };

  const handleCopy = (data) => {
    setCopiedDisclaimer(data);
    setCopyModalOpen(true);
  };

  const handleCloseCopy = () => {
    setCopiedDisclaimer({ id: 0, moboOutlet: "", type: "", language: "" });
    setCopyToLanguage("0");
    setCopyToOutlet(0);
    setCopyModalOpen(false);
  };

  const handleSubmitCopy = async () => {
    setRequestInProgress(true);
    var result = await copyDisclaimer(
      JSON.stringify({
        sourceId: copiedDisclaimer.id,
        moboOutletId: copyToOutlet,
        language: copyToLanguage,
      })
    );

    setRequestInProgress(false);
    handleCloseCopy();

    if (result !== undefined) {
      DisplayMessage(resources["DisclaimerWasCopiedWithSuccess"], "success");
      if (copyToOutlet === selectedOutlet || selectedOutlet === 0) {
        setDisclaimers([
          ...disclaimers,
          {
            id: result.id,
            moboOutlet: getMoboOutletDescription(result.moboOutletId),
            type: getTypeDescription(result.type),
            language: result.language,
          },
        ]);
      }
    }
  };

  const getMoboOutletDescription = (moboOutletId) => {
    let moboOutlet = accessibleMoboOutlets.find(
      (outlet) => outlet.id === moboOutletId
    );
    return moboOutlet?.description ?? null;
  };

  const getTypeDescription = (type) => {
    switch (type) {
      case 1:
        return resources["DisclaimerType1"];
      case 2:
        return resources["DisclaimerType2"];
      case 3:
        return resources["DisclaimerType3"];
      case 4:
        return resources["DisclaimerType4"];
      default:
        return resources["Unknown"];
    }
  };

  const getData = (outletId) => {
    var fetchedDisclaimers = [];

    setFetchingData(true);
    getDisclaimers(outletId).then((data) => {
      if (data === undefined) {
        setDisclaimers([]);
        setFetchingData(false);

        return;
      }
      if (data.length === 0) {
        setDisclaimers([]);
      } else {
        data.forEach((item) => {
          fetchedDisclaimers.push({
            id: item.id,
            moboOutlet: getMoboOutletDescription(item.moboOutletId),
            type: getTypeDescription(item.type),
            language: item.language,
          });
        });
        setDisclaimers(fetchedDisclaimers);
      }
      setFetchingData(false);
    });
  };

  useEffect(() => {
    if (refreshGrid !== true) {
      getData(selectedOutlet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refresh === true) {
      getData(selectedOutlet);
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (refreshGrid === true) {
      getData(selectedOutlet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  const handleChangeOutlet = (event) => {
    setSelectedOutlet(parseInt(event.target.value));
    setRefresh(true);
  };

  const handleChangeOutletToCopy = (event) => {
    setCopyToOutlet(parseInt(event.target.value));
  };

  const generateOutletsOptions = () => {
    if (accessibleMoboOutlets === undefined) return;
    var outlets = accessibleMoboOutlets.sort((a, b) =>
      a.description > b.description ? 1 : -1
    );

    var outletOptions = outlets.map((x) => (
      <option key={x.id} value={x.id}>
        {x.description}
      </option>
    ));
    return outletOptions;
  };

  const generateLanguageOptions = () => {
    var languageOptions = LanguagesWithLanguageCode.map((x) => (
      <option key={x.code} value={x.code}>
        {x.Name}
      </option>
    ));

    return languageOptions;
  };

  const handleChangeLanguage = (event) => {
    setCopyToLanguage(event.target.value);
  };

  const generateInfo = () => {
    return (
      <>
        <Box display="flex">
          <Box marginRight="12px" minWidth="100px">
            <Typography> {resources["Outlet"]}: </Typography>
          </Box>
          <Box sx={{ wordBreak: "break-word" }}>
            <Typography> {copiedDisclaimer.moboOutlet} </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box marginRight="12px" minWidth="100px">
            <Typography> {resources["Type"]}: </Typography>
          </Box>
          <Box sx={{ wordBreak: "break-word" }}>
            <Typography> {copiedDisclaimer.type} </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box marginRight="12px" minWidth="100px">
            <Typography> {resources["Language"]}: </Typography>
          </Box>
          <Box sx={{ wordBreak: "break-word" }}>
            <Typography> {copiedDisclaimer.language} </Typography>
          </Box>
        </Box>{" "}
      </>
    );
  };

  return (
    <Box>
      <Box className="position-relative py-4">
        <Typography variant="h3">{resources["Disclaimers"]}</Typography>
        <Box
          className={
            "offcentered-loader " + (fetchingData ? "fadeIn" : "fadeOut")
          }
        >
          <CircularProgress />
        </Box>
        <Box className={"mt-4 " + (fetchingData ? "fadeOut" : "fadeIn")}>
          <Box>
            <FormControl className="main-dropdown" variant="outlined">
              <InputLabel id="outlet_dropdown">
                {resources["Outlet"]}
              </InputLabel>
              <Select
                native
                id="outlet_selected"
                value={selectedOutlet}
                label={resources["Outlet"]}
                onChange={handleChangeOutlet}
                inputProps={{
                  name: "outlet",
                  id: "outlined-outlet-native-simple",
                }}
              >
                <option value={0}>{resources["All"]}</option>
                {generateOutletsOptions()}
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="flex-end" sx={{ mb: "10px" }}>
              <Link
                to={{
                  pathname: "/disclaimers/new",
                  state: { outletId: selectedOutlet },
                }}
                style={{ textDecoration: "none" }}
              >
                <Button id="add-Disclaimer" variant="contained" color="primary">
                  <i
                    className="fas fa-plus mr-2"
                    title={resources["AddNewDisclaimer"]}
                  />
                  {resources["AddNewDisclaimer"]}
                </Button>
              </Link>
            </Box>
          </Box>
          <Box style={{ clear: "both" }}></Box>
          <Paper elevation={3} className="p-3 mt-3">
            <ThemeProvider theme={LocalizationTheme()}>
              <MaterialTable
                icons={TableIcons}
                title={""}
                localization={{
                  header: { actions: resources["Actions"] },
                  body: {
                    emptyDataSourceMessage: resources["NoRecordsToBeDisplayed"],
                    deleteTooltip: resources["DeleteTooltip"],
                    editTooltip: resources["EditTooltip"],
                  },
                  toolbar: {
                    searchPlaceholder: resources["Search"],
                    searchTooltip: resources["Search"],
                  },
                  pagination: {
                    firstTooltip: resources["FirstTooltip"],
                    lastTooltip: resources["LastTooltip"],

                    previousTooltip: resources["PreviousTooltip"],
                    nextTooltip: resources["NextTooltip"],

                    labelRowsPerPage: resources["LabelRowsPerPage"],
                    labelRowsSelect: resources["LabelRowsSelect"],
                  },
                }}
                data={disclaimers}
                columns={tableSettings.columns}
                actions={tableSettings.actions}
                options={{
                  emptyRowsWhenPaging: false,
                  pageSize: 10,
                  pageSizeOptions: [10, 15, 20],
                }}
              />
            </ThemeProvider>
          </Paper>
        </Box>
        <Modal
          BackdropProps={{ style: { backgroundColor: "#0000004D" } }}
          open={copyModalOpen}
          onClose={handleCloseCopy}
          className={classes.modal}
        >
          <Zoom in={copyModalOpen} timeout={350}>
            <ModalContent
              className="bounce-in"
              style={{
                transform: copyModalOpen ? "scale(1)" : "scale(0.3)",
              }}
            >
              <Box marginY={2}>
                <Typography>{resources["CopyDisclaimer"]}</Typography>
              </Box>
              <Box marginBottom={2}>{generateInfo()}</Box>
              <Box marginBottom={2.5}>
                <Typography>{resources["To"]}:</Typography>
              </Box>
              <Box display="flex" flexDirection="column" marginBottom={2}>
                <Box marginBottom={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="copy_outlet_dropdown">
                      {resources["Outlet"]}
                    </InputLabel>
                    <Select
                      native
                      id="outlet_selected_to_copy"
                      value={copyToOutlet}
                      label={resources["Outlet"]}
                      onChange={handleChangeOutletToCopy}
                    >
                      <option value={0}>{resources["Select"]}</option>
                      {generateOutletsOptions()}
                    </Select>
                    <FormHelperText error={copyToOutlet === 0}>
                      {resources["SelectionOfOutletIsMandatory"]}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="language_dropdown">
                      {resources["Language"]}
                    </InputLabel>
                    <Select
                      native
                      id="language_selected"
                      value={copyToLanguage}
                      label={resources["Language"]}
                      onChange={handleChangeLanguage}
                    >
                      <option value={0}>{resources["Select"]}</option>
                      {generateLanguageOptions()}
                    </Select>
                    <FormHelperText error={copyToLanguage === "0"}>
                      {resources["SelectionOfLanguageIsMandatory"]}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" marginTop={3}>
                <CancelButton id="btnCancelCopy" onClick={handleCloseCopy}>
                  {resources["Cancel"]}
                </CancelButton>
                <OkButton
                  variant="contained"
                  id="btnCopy"
                  disabled={
                    requestInProgress ||
                    copyToLanguage === "0" ||
                    copyToOutlet === 0
                  }
                  onClick={handleSubmitCopy}
                >
                  {resources["Ok"]}
                </OkButton>
              </Box>
              <span className="rodal-close" onClick={handleCloseCopy}></span>
            </ModalContent>
          </Zoom>
        </Modal>
        <Modal
          BackdropProps={{ style: { backgroundColor: "#0000004D" } }}
          open={deleteModalOpen}
          onClose={handleCloseDelete}
          className={classes.modal}
        >
          <Zoom in={deleteModalOpen} timeout={350}>
            <ModalContent
              className="bounce-in"
              style={{
                transform: deleteModalOpen ? "scale(1)" : "scale(0.3)",
              }}
            >
              <Box
                mt={2.5}
                padding={2.5}
                minHeight={118}
                alignContent={"center"}
              >
                <Typography align="center">
                  {resources["AreYouSureYouWantToDeleteDisclaimer"]}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end" padding={1.5}>
                <CancelButton id="btnCancelDelete" onClick={handleCloseDelete}>
                  {resources["Cancel"]}
                </CancelButton>
                <OkButton
                  id="btnDelete"
                  onClick={handleSubmitDelete}
                  disabled={requestInProgress}
                >
                  {resources["Yes"]}
                </OkButton>
              </Box>
              <span className="rodal-close" onClick={handleCloseDelete}></span>
            </ModalContent>
          </Zoom>
        </Modal>
      </Box>
    </Box>
  );
};

export default DisclaimersTable;
